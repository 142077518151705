import React from 'react'
import { useStaticQuery, graphql, PageProps } from 'gatsby'
import Theme from 'styles/Global'
import styled from 'styled-components'
import { SeoProps } from 'components/shared/SEO'

// Layout
import Page from 'templates/page'
import Post from 'templates/post'

// CSS
import 'bootstrap/dist/css/bootstrap.css'
import 'styles/all.css'
import 'styles/preview.css'

interface TemplateProps {
  [key: string]: any
}

interface PageTemplateProps extends Omit<PageProps, 'data'> {
  data: {
    page: {
      title: string
      path: string
      flexContent: any
      acf: any
      seo: SeoProps
    }
  }
  prefix: string
}

interface ConvertTypes {
  [key: string]: any
}

const PreviewTemplate: React.FC<PageProps> = ({ location }) => {
  const urlParams = new URLSearchParams(location.search)
  const postId = urlParams.get('post_id')

  // @ts-ignore
  const { site } = useStaticQuery<GatsbyTypes.previewQueryQuery>(graphql`
    query previewQuery {
      site {
        siteMetadata {
          siteWpUrl
        }
      }
    }
  `)

  const [revision, setRevision] = React.useState<any>(null)
  const [status, setStatus] = React.useState<'fetching' | 'error' | 'done'>(
    'fetching'
  )

  const templates: TemplateProps = {
    page: { component: Page },
    post: { component: Post },
  }

  const postTypeConverters = () => {
    const converters: ConvertTypes = {}

    Object.keys(templates)
      .filter((name: string) => !!templates[name].prefix)
      .forEach((name: string) => {
        converters[name] = templates[name].prefix
      })

    return converters
  }

  React.useEffect(() => {
    ;(async () => {
      try {
        const response = await fetch(
          `${
            site?.siteMetadata?.siteWpUrl
          }/wp-json/preview/v1/revision?post_id=${postId}&convert=${encodeURIComponent(
            JSON.stringify(postTypeConverters())
          )}`,
          { method: 'GET' }
        )

        const data = await response.json()

        setRevision(data)
        setStatus('done')
      } catch (error) {
        setStatus('error')
      }
    })()
  }, [])

  if (status === 'fetching') {
    return <Message content="Voorbeeld aan het laden ..." />
  }

  if (status === 'error') {
    return <Message content="Er ging iets fout" />
  }

  if (!revision || !revision.acf) {
    return (
      <Message
        content="Geen revisie(s) gevonden"
        subContent={`Ondersteunt berichttype "${revision.post_type}" revisies?`}
      />
    )
  }

  if (!templates[revision.post_type]) {
    return (
      <Message
        content={`Geen sjabloon gevonden voor berichttype "${revision.post_type}"`}
        subContent="Koppel het berichttype aan een sjabloon"
      />
    )
  }

  const templateData = {
    data: {
      page: {
        title: revision.post_title,
        path: location.pathname,
        seo: {
          title: revision.post_title,
        },
        ...revision.acf,
      },
    },
    location,
    prefix: `${revision.post_type}_Flexcontent`,
  } as PageTemplateProps

  const PageTemplate: React.FC<PageTemplateProps> =
    templates[revision.post_type].component

  return (
    <div className="preview">
      {/* eslint-disable-next-line react/jsx-props-no-spreading */}
      <PageTemplate {...templateData} />
    </div>
  )
}

const MessageContainer = styled.div`
  background-color: #f0f0f1;
  height: 100vh;
  width: 100vw;
`

const MessageContent = styled.h1`
  font-family: ${({ theme }) => theme.font.family.primary};
  font-size: 23px;
  color: #3c434a;
  text-align: center;
`

const MessageSubContent = styled.h2`
  font-family: ${({ theme }) => theme.font.family.primary};
  font-size: 14px;
  font-style: italic;
  color: #646970;
  text-align: center;
`

const MessageBar = styled.div`
  background: #1d2327;
  width: 100%;
  height: 32px;
`

interface MessageProps {
  content: string
  subContent?: string
}

const Message: React.FC<MessageProps> = ({ content, subContent = '' }) => (
  <Theme>
    <MessageContainer>
      <MessageBar />
      <div className="py-5 d-flex justify-content-center">
        <div>
          <MessageContent>{content}</MessageContent>
          {subContent && (
            <MessageSubContent className="mt-3">{subContent}</MessageSubContent>
          )}
        </div>
      </div>
    </MessageContainer>
  </Theme>
)

export default PreviewTemplate
